@font-face {
    font-family: 'GTWalsheim';
    src: url('#{$fonts}GTWalsheimLight.woff2') format('woff2'),
        url('#{$fonts}GTWalsheimLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('#{$fonts}GTWalsheimRegular.woff2') format('woff2'),
        url('#{$fonts}GTWalsheimRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('#{$fonts}GTWalsheimMedium.woff2') format('woff2'),
        url('#{$fonts}GTWalsheimMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('#{$fonts}GTWalsheimBold.woff2') format('woff2'),
        url('#{$fonts}GTWalsheimBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
// dev 2

// dev 3

// dev 4

// dev 5

body {
	margin: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	color: $body-color;
	background: $body-bg;
	min-width: 320px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;

	@include media('<desktop') {
		font-size: $tablet-font-size-base;
		line-height: $tablet-line-height-base;
	}

	@include media('<tablet') {
		-webkit-text-size-adjust: none;
		font-size: $mobile-font-size-base;
		line-height: $mobile-line-height-base;
	}
}

img {
	border-style: none;
}

input,
textarea,
select {
	color: $black;
	vertical-align: middle;
	font: 100% $font-family-base;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: all 0.3s;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	border: none;
	padding: 0;
}

form,fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

blockquote,
q {
	quotes: none;
}

q:before,
q:after {
	content: '';
}

::-moz-placeholder,
:-moz-placeholder {
	opacity: 1;
}

::-moz-placeholder{
	color: $body-color;
}

:-ms-input-placeholder{
	color: $body-color;
}

::-webkit-input-placeholder{
	color: $body-color;
}

a {
	color: $link-color;
	text-decoration: underline;
	transition: all 0.3s;
}

a:hover {
	text-decoration: none;
	color: $link-hover-color;
}

a:focus {
	color: $link-hover-color;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
	outline: none !important;
}

header,
footer,
section,
nav,
article,
aside {
	display: block;
}

// wrapper
.wrapper {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

// container
.container {
	@include media('<mddesktop') {
		max-width: none;
		padding-left: 30px;
		padding-right: 30px;
	}

	@include media('<tablet') {
		padding-left: 20px;
		padding-right: 20px;
	}
}

// headings
h1 {
	margin: 0 0 15px;
	line-height: 1.161;

	@include media('<mddesktop') {
		font-size: 45px;
	}

	@include media('<tablet') {
		font-size: 30px;
		line-height: 1.2;
	}
}

h2 {
	margin: 0 0 15px;
}

h3 {
	margin: 0 0 15px;
}

h4 {
	margin: 0 0 15px;
}

h5 {
	margin: 0 0 15px;
}

h6 {
	margin: 0 0 15px;
}

// bg-img
.bg-img {
	@include bg-cover();

	& > img {
		display: none;
	}
}

//  cols style
.row-20 {
	@include grid-gutter(20px);
}

.row-0 {
	@include grid-gutter(0px);
}

// list-unstyled
.list-unstyled {
	list-style: none;
	margin: 0;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	text-align: center;

	& > li {
		width: 100%;

		& + li {
			padding-top: 15px;
		}

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				text-decoration: underline;
				color: inherit;
			}
		}
	}
}

// title-label
.title-label {
	display: inline-block;
	vertical-align: top;
	color: inherit;
	background-color: $yellow;
	padding: 11px 18px;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 1.2;
	font-weight: 700;
	margin-bottom: 20px;
	letter-spacing: 2px;

	@include media('<tablet') {
		padding: 8px 17px;
		font-size: 12px;
		margin-bottom: 20px;
		letter-spacing: 1.5px;
	}
}

// dev 2

// dev 3

// dev 4

// dev 5

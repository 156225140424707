// landing-area
.landing-area {
	position: relative;

	.container {
		padding-bottom: 11.1vh;
	}

	h1 {
		margin-bottom: 28px;
		letter-spacing: -2px;
	}

	p {
		margin: 0 0 31px;
	}

	@include media('<mddesktop') {
		.container {
			padding-bottom: 30px;
		}
	}

	@include media('<tablet') {
		.container {
			padding-bottom: 26px;
		}

		h1 {
			margin-bottom: 19px;
			letter-spacing: -0.5px;
		}

		.btn {
			display: block;
			min-width: 0;
		}
	}
}

// btn
.btn {
	position: relative;
	vertical-align: top;
	outline: 0 !important;

	@include button-size(15px, 20px, 14px, 1.286, 4px);

	font-weight: 500;
	letter-spacing: 0;
	text-transform: uppercase;
	border: solid 1px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	transition: all .3s;
	min-width: 180px;

	&:hover {
		text-decoration: none;
	}

	&-lg {
		@include button-size(0px, 0px, 0px, 0, 0);
	}

	&-md {
		@include button-size(0px, 0px, 0px, 0, 0);
	}

	&-sm {
		@include button-size(0px, 0px, 0px, 0, 0);
	}

	&-default {
		color: $white;
		box-shadow: none;

		@include button-variant($black, $black, darken($black, 5%), darken($black, 5%), darken($black, 5%), darken($black, 5%));

		&:focus,
		&.focus {
			box-shadow: none !important;
		}
	}

	&-primary {
		color: $white;
		box-shadow: 0 2px 8px rgba($black, 0.14);

		@include button-variant($gray-800, $gray-800, $yellow, $yellow, $yellow, $yellow);

		&:active,
		&.active,
		.open > &.dropdown-toggle,
		&:focus,
		&.focus,
		&:active:hover,
		&.active:hover,
		.open > &.dropdown-toggle:hover,
		&:active:focus,
		&.active:focus,
		.open > &.dropdown-toggle:focus,
		&.focus:active,
		&.active.focus,
		.open > &.dropdown-toggle.focus,
		&:hover {
			box-shadow: 0 2px 8px rgba($black, 0.14) !important;
			color: $gray-800;
		}
	}

	&-danger {
		color: $white;
		box-shadow: none;

		@include button-variant($black, $black, darken($black, 5%), darken($black, 5%), darken($black, 5%), darken($black, 5%));

		&:focus,
		&.focus {
			box-shadow: none !important;
		}
	}

	&-success {
		color: $white;
		box-shadow: none;

		@include button-variant($black, $black, darken($black, 5%), darken($black, 5%), darken($black, 5%), darken($black, 5%));

		&:focus,
		&.focus {
			box-shadow: none !important;
		}
	}

	&-info {
		color: $white;
		box-shadow: none;

		@include button-variant($black, $black, darken($black, 5%), darken($black, 5%), darken($black, 5%), darken($black, 5%));

		&:focus,
		&.focus {
			box-shadow: none !important;
		}
	}

	&-link {
		color: $black;
		background: transparent;
		border-color: transparent;

		@include button-hover(transparent, transparent, $black);
	}

	&.has-shadow {
		box-shadow: 0 0 15px rgba($black, 0.1);
	}

	input {
		position: absolute;
		z-index: 25;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border: 0;
		width: 100%;
		height: 100%;
		background: none;
		opacity: 0;
		padding: 0;
		overflow: hidden;
		text-indent: -9999px;
	}
}

// dev 2

// dev 3

// dev 4

// dev 5

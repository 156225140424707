// phone-block
.phone-block {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 58px -40px -41px 0;

	.bg-pattern {
		position: absolute;
		z-index: 5;
		top: 29px;
		bottom: -4px;
		left: 0;
		right: 0;

		img {
			position: absolute;
			z-index: 5;
			height: 100%;
			max-height: 100%;
			width: auto;
			top: 0;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}

	@include media('<mddesktop') {
		margin-top: 0;
		margin-bottom: 0;
		margin-right: -60px;
	}

	@include media('<desktop') {
		margin: 0 0 40px;
		display: block;
		text-align: center;
	}

	@include media('<tablet') {
		margin: -16px 0 12px;

		.bg-pattern {
			top: 45px;
			bottom: 54px;
		}
	}
}

// phone-device
.phone-device {
	display: inline-block;
	vertical-align: top;
	position: relative;
	z-index: 20;
	overflow: hidden;
	width: 423px;
	height: 715px;
	padding: 126px 97px 145px 75px;
	background: url(#{$images}bg-phone.png) no-repeat;
	background-size: 100% 100%;

	@include media('<tablet') {
		width: 235px;
		height: 400px;
		padding: 70px 52px 81px 42px;
	}
}

@mixin blank-text() {
	font-size: 0;
	line-height: 0;
}

@mixin bg-cover($position: 50% 50%) {
	background-size: cover;
	background-position: $position;
	background-repeat: no-repeat;
}

@mixin position-over($zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin center-image($maxWidth: 80%, $maxHeight: 80%, $zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	max-width: $maxWidth;
	max-height: $maxHeight;
}

@mixin button-hover($bgColor, $borderColor, $color) {
	&:active,
	&.active,
	.open > &.dropdown-toggle,
	&:focus,
	&.focus,
	&:active:hover,
	&.active:hover,
	.open > &.dropdown-toggle:hover,
	&:active:focus,
	&.active:focus,
	.open > &.dropdown-toggle:focus,
	&.focus:active,
	&.active.focus,
	.open > &.dropdown-toggle.focus,
	&:hover {
		background-color: $bgColor;
		border-color: $borderColor;
		color: $color;
	}
}

@mixin gradient-radial-three-colors($inner-center-color: #000, $inner-middle-color: #666, $outer-color: #ccc, $middle-position: 50%) {
	background: -moz-radial-gradient(center, ellipse cover, $inner-center-color 0%, $inner-middle-color $middle-position, $outer-color 100%);
	background: -webkit-radial-gradient(center, ellipse cover, $inner-center-color 0%,$inner-middle-color $middle-position,$outer-color 100%);
	background: radial-gradient(ellipse at center, $inner-center-color 0%,$inner-middle-color $middle-position,$outer-color 100%);
}

@mixin gradient-diagonal-four-colors($rotate: -45deg, $inner-center-color: #000, $inner-middle-color1: #666, $inner-middle-color2: #666, $outer-color: #ccc, $middle-position1: 50%, $middle-position2: 50%) {
	background: -moz-linear-gradient($rotate, $inner-center-color 0%, $inner-middle-color1 $middle-position1, $inner-middle-color2 $middle-position2, $outer-color 100%);
	background: -webkit-linear-gradient($rotate, $inner-center-color 0%,$inner-middle-color1 $middle-position1,$inner-middle-color2 $middle-position2,$outer-color 100%);
	background: linear-gradient($rotate, $inner-center-color 0%,$inner-middle-color1 $middle-position1,$inner-middle-color2 $middle-position2,$outer-color 100%);
}

@mixin datepickerDayColor($background: #000, $color: #fff) {
	background: $background;
	color: $color;
	text-shadow: none;
}

@mixin grid-gutter($gutter-width) {
	margin-left: -$gutter-width/2;
	margin-right: -$gutter-width/2;

	& > [class^="col"] {
		padding-left: $gutter-width/2;
		padding-right: $gutter-width/2;
	}
}

@mixin placeholder($color: $input-color-placeholder) {
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder  {
    color: $color;
  }
}

// dev 2

// dev 3

// dev 4

// dev 5

// vars
$fonts: '../fonts/';
$images: '../images/';

// colors
$white:    #fff !default;
$gray-100: #efeff0;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #5d5c66;
$gray-700: #495057 !default;
$gray-800: #25232e;
$gray-900: #212529 !default;
$black:    #000 !default;
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #fab755;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$light-gray: #fafafa;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// bootstrap vars
$breakpoints: (
	phone: 375px,
	tablet: 768px,
	desktop: 992px,
	minscroll: 1025px,
	mddesktop: 1240px
);

// bootstrap vars
$grid-breakpoints: (
	xs: 0,
	sm: 340px,
	md: 768px,
	lg: 992px,
	xl: 1240px
);
$container-max-widths: (
	md: 750px,
	lg: 980px,
	xl: 1212px
);

// grid
$grid-gutter-width: 30px;

// fonts
$GTWalsheim: 'GTWalsheim', Arial, Helvetica, sans-serif;

// settings
$body-bg: $light-gray;
$body-color: $gray-800;
$font-size-base: 1.25rem;
$line-height-base: 1.6;
$font-family-base: $GTWalsheim;
$font-weight-base: 300;
$tablet-font-size-base: 1.25rem;
$tablet-line-height-base: 1.6;
$mobile-font-size-base: 1.25rem;
$mobile-line-height-base: 1.4;

$link-color: inherit;
$link-hover-color: inherit;

// headings
$headings-font-family: $font-family-base;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: $body-color;

$h1-font-size: 56px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 16px;

// dev 2

// dev 3

// dev 4

// dev 5

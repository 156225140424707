// header
#header {
	position: relative;
	z-index: 100;

	.container {
		padding-top: 34px;
		padding-bottom: 34px;
	}

	.logo {
		width: 51px;
		height: 50px;
		background: url(#{$images}logo.svg) no-repeat;
		background-size: 51px 50px;
		display: inline-block;
		vertical-align: top;

		@include blank-text();

		position: relative;
		overflow: hidden;

		a {
			display: block;
			height: 100%;
		}
	}

	.languages {
		list-style: none;
		margin: 0 -7px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;

		& > li {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 1.2;
			font-weight: 400;
			padding: 0 7px;

			& > a {
				text-decoration: none;
				color: $gray-800;
				opacity: 0.3;

				&:hover {
					text-decoration: none;
					opacity: 1;
				}
			}

			&.active {
				& > a {
					opacity: 1;
				}
			}
		}
	}

	@include media('<tablet') {
		.container {
			padding-top: 16px;
			padding-bottom: 16px;
		}

		.logo {
			width: 31px;
			height: 30px;
			background-size: 31px 30px;
		}
	}
}

// dev 2

// dev 3

// dev 4

// dev 5

// phone-module
.phone-module {
	position: relative;
	z-index: 20;
	overflow: hidden;
	width: 251px;
	height: 444px;
	background-color: $black;
	padding: 64px 22px 20px;
	text-align: left;

	.phone-title {
		width: 81px;
		position: relative;
		overflow: hidden;
		margin: 0 auto 58px;

		img {
			display: block;
			width: 100%;

			@include img-fluid();
		}
	}

	.title-item {
		background-color: $yellow;
		padding: 12px 15px;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;
		position: relative;
		border-radius: 3px;
		margin-bottom: 22px;

		&::before {
			content: "";
			position: absolute;
			z-index: 5;
			top: 100%;
			left: 11px;
			right: 11px;
			border-radius: 0 0 3px 3px;
			background-color: $yellow;
			opacity: 0.5;
			height: 12px;
		}

		&::after {
			content: "";
			position: absolute;
			z-index: 6;
			top: 100%;
			left: 6px;
			right: 6px;
			border-radius: 0 0 3px 3px;
			background-color: $yellow;
			opacity: 0.9;
			height: 6px;
		}

		.ico {
			position: relative;
			overflow: hidden;
			width: 30px;
			height: 30px;
			max-width: 30px;
			flex: 0 0 30px;
			margin: 0 16px 0 0;

			img {
				display: block;
				width: 100%;

				@include img-fluid();
			}
		}

		h3 {
			margin: 0;
			color: $gray-800;
			font: 700 12px/1.5 $GTWalsheim;
		}
	}

	.phone-buttons {
		list-style: none;
		margin: 0;
		padding: 0;

		& > li {
			padding-bottom: 10px;
			display: flex;
			width: 100%;

			& > a {
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				align-items: center;
				text-decoration: none;
				color: $gray-100;
				font: 700 12px/1.5 $GTWalsheim;
				position: relative;
				padding: 10px 15px 10px 62px;
				min-height: 60px;
				flex-grow: 1;
				border-radius: 3px;
				background-color: $gray-600;

				&::before {
					content: "";
					position: absolute;
					z-index: 5;
					top: 50%;
					left: 0;
					width: 8px;
					height: 8px;
					border-radius: 100%;
					background-color: $yellow;
					transform: translate(0, -50%);
					margin: 0 0 0 25px;
					transition: all 0.3s;
				}

				&:hover {
					text-decoration: none;
					color: $gray-800;
					background-color: $yellow;

					&::before {
						background-color: $gray-800;
					}
				}
			}
		}
	}

	@include media('<tablet') {
		width: 140px;
		height: 249px;
		padding: 34px 12px 10px;

		.phone-title {
			width: 51px;
			margin-bottom: 26px;
		}

		.title-item {
			padding: 7px 2px 6px 8px;
			margin-bottom: 17px;

			&::before {
				left: 6px;
				right: 6px;
				height: 8px;
			}

			&::after {
				left: 3px;
				right: 3px;
				height: 4px;
			}

			.ico {
				width: 17px;
				height: 17px;
				max-width: 17px;
				flex: 0 0 17px;
				margin: 0 9px 0 0;
			}

			h3 {
				font-size: 11px;
				line-height: 1.182;
			}
		}

		.phone-buttons {
			& > li {
				padding-bottom: 8px;

				& > a {
					font-size: 11px;
					line-height: 1.182;
					padding: 5px 2px 5px 35px;
					min-height: 40px;

					&::before {
						width: 5px;
						height: 5px;
						margin-left: 15px;
					}
				}
			}
		}
	}
}

// footer
#footer {
	position: relative;
	font-size: 12px;
	line-height: 1.2;
	font-weight: 400;

	.container {
		padding-top: 22px;
		padding-bottom: 22px;
	}

	p {
		margin: 0;
	}

	@include media('<tablet') {
		text-align: center;

		.container {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

// dev 2

// dev 3

// dev 4

// dev 5
